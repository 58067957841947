import axios from 'axios';
import localforage from 'localforage';
import {
   API_URL,
   API_ERROR_500_MESSAGE,
   APP_ERROR_MESSAGE,
   API_ERROR_UNKNOWN_MESSAGE,
   ADMIN_AGRO_AUTH_TOKEN,
   ADMIN_AGRO_AUTH_USER,
   AUTH_TOKEN_CACHE_TTL,
   AUTH_USER_CACHE_TTL,
   WORDPRESS_URL
} from '../config/app';

/**
 * Logs in the user
 *
 * @param {string} email
 * @param {string} password
 */
export const logInUser = async (email, password, guest) => {
   const response = {
      has_error: false,
      data: {},
      errors: []
   };
   try {
      console.log('here is the type ', guest?.type);
      let serverResponse;
      if (guest?.type === 'Guest') {
         const url = `${API_URL}/guest-users`;
         serverResponse = await axios.request({
            url,
            method: 'POST',
            data: {
               name: guest?.name || 'Guest',
               type: guest?.userType || 'Farmer'
            },
            validateStatus: status => {
               if (status >= 200 && status < 500) {
                  return true;
               }
               return false;
            }
         });
      } else {
         const url = `${API_URL}/auth/local`;
         serverResponse = await axios.request({
            url,
            method: 'POST',
            data: {
               identifier: email,
               password: password
            },
            validateStatus: status => {
               if (status >= 200 && status < 500) {
                  return true;
               }
               return false;
            }
         });
         console.log('login server response logInUser()', serverResponse);
      }
      switch (serverResponse.status) {
         case 200:
            const token = serverResponse?.data?.jwt || '';
            // const {
            //   store_id,
            //   user_email,
            //   user_nicename,
            //   user_display_name,
            //   roles,
            // } = serverResponse;
            console.log('Successful login response logInUser()', serverResponse);
            let user;
            if (guest?.type === 'Guest') {
               user = {
                  id: 1,
                  username: guest?.name || 'Guest',
                  email: 'Guest',
                  roles: 'Guest'
               };
            } else {
               user = {
                  id: serverResponse?.data?.user?.id,
                  username: serverResponse?.data?.user?.username,
                  email: serverResponse?.data?.user?.email,
                  roles: serverResponse?.data?.user?.role?.name
               };
            }
            if (token) {
               await storeAuthUserTokenInLocalStorage(token);
            }

            if (user) {
               await storeAuthUserInfoInLocalStorage(user);
            }
            console.log('login response', user);
            response.data.url = '/overview';

            break;
         case 400:
            const errors = serverResponse.data.data[0].messages;
            response.has_error = true;
            response.errors.push(errors[0].message);
            break;
         case 500:
            response.has_error = true;
            response.errors.push(API_ERROR_500_MESSAGE);
            break;
         default:
            response.has_error = true;
            response.errors.push(API_ERROR_UNKNOWN_MESSAGE);
            break;
      }
   } catch (err) {
      response.has_error = true;
      response.errors.push(APP_ERROR_MESSAGE);
   }

   return response;
};

/**
 * Stores user auth token in the localstorage
 *
 * @param {string} token
 */
export const storeAuthUserTokenInLocalStorage = async token => {
   try {
      var now = new Date();
      const item = {
         value: token,
         expiry: now.getTime() + AUTH_TOKEN_CACHE_TTL
      };
      await localforage.setItem(ADMIN_AGRO_AUTH_TOKEN, item);
   } catch (e) {
      // console.log(
      //   "an error happened when storing user in localstorage storeUserAuth()",
      //   e
      // );
   }
};

/**
 * Stores the user info in the localstorage
 *
 * @param {object} userInfo
 */
export const storeAuthUserInfoInLocalStorage = async userInfo => {
   try {
      var now = new Date();
      const item = {
         value: userInfo,
         expiry: now.getTime() + AUTH_USER_CACHE_TTL
      };
      await localforage.setItem(ADMIN_AGRO_AUTH_USER, item);
   } catch (err) {
      // console.log(
      //   "an error happened when trying to store auth user info in localstorage storeAuthUserInfoInLocalStorage()",
      //   err
      // );
   }
};

/**
 * Retrieves the user auth token from the local storage
 *
 */
export const retriveAuthTokenFromLocalStorage = async () => {
   var authToken = null;
   try {
      var tokenData = await localforage.getItem(ADMIN_AGRO_AUTH_TOKEN);
      if (tokenData) {
         var now = new Date();
         if (now.getTime() < tokenData.expiry) {
            authToken = tokenData.value;
         } else {
            /**
             * Delete expired token
             */
            await localforage.removeItem(ADMIN_AGRO_AUTH_TOKEN);
         }
      }
   } catch (e) {
      // console.log(
      //   "an error happened when trying to retriveAuthTokenFromLocalStorage()",
      //   e
      // );
   }
   return authToken;
};

/**
 * Retrieves the user info in the local storage
 */
export const retrieveUserInfoInLocalStorage = async () => {
   var user = null;
   try {
      var userData = await localforage.getItem(ADMIN_AGRO_AUTH_USER);
      if (userData) {
         var now = new Date();
         if (now.getTime() < userData.expiry) {
            user = userData.value;
         } else {
            /**
             * TO DO
             * Retrieve user info from the api if user is logged in
             */
            await localforage.removeItem(ADMIN_AGRO_AUTH_USER);
         }
      }
   } catch (e) {
      // console.log(
      //   "an error happened when trying to retrieveUserInfoInLocalStorage()",
      //   e
      // );
   }
   return user;
};

/**
 * Checks if user is logged in
 */
export const isUserLoggedIn = async () => {
   let userLoggedInValidity = false;
   try {
      var token = await retriveAuthTokenFromLocalStorage();
      if (token) {
         userLoggedInValidity = true;
      }
   } catch (e) {
      // console.log(
      //   "an error happened when checking if user is logged in isUserLoggedIn()",
      //   e
      // );
   }
   return userLoggedInValidity;
};

export const logoutUser = async () => {
   try {
      await localforage.removeItem(ADMIN_AGRO_AUTH_TOKEN);
      await localforage.removeItem(ADMIN_AGRO_AUTH_USER);
   } catch (e) {
      // console.log(
      //   "an error happened when trying to log out user in auth/logoutUser()",
      //   e
      // );
   }
};
