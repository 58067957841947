import React, { Fragment } from 'react';
import AppHeader from '../Layout/AppHeader/';
import AppSidebar from '../Layout/AppSidebar/';
// import ThemeOptions from '../Layout/ThemeOptions/';
import { isUserLoggedIn, retrieveUserInfoInLocalStorage } from '../helpers/auth';
import { Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

class Global extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         isAllowed: true
      };
      this.initializeComponent = this.initializeComponent.bind(this);
   }

   componentDidMount() {
      this.initializeComponent();
   }

   initializeComponent = async () => {
      try {
         console.log('Working till there');
         const user = await retrieveUserInfoInLocalStorage();
         if ( user?.roles !== 'Guest' && !(await isUserLoggedIn()) ) {
            console.log('if condition is flased');
            this.setState({
               isAllowed: false
            });
         }
      } catch (err) {
         console.log('an error happened when initializing component in Global /src/common/Global', err);
      }
   };

   render() {
      const { isAllowed } = this.state;
      return (
         <Fragment>
            {/* <ThemeOptions/> */}
            <AppHeader />
            <ToastContainer />
            <div className="app-main">
               <AppSidebar />
               {isAllowed ? (
                  <div className="app-main__outer">
                     <div className="app-main__inner">{this.props.children}</div>
                  </div>
               ) : (
                  <Redirect to="/" />
               )}
            </div>
         </Fragment>
      );
   }
}

export default Global;
