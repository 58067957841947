import { API_URL, CLOUDINARY_PRESET } from '../config/app';
import { getRemoteData } from './remote';
import { uploadImageOnCloudinary } from './cloudinary';
import { retrieveUserInfoInLocalStorage } from './auth';

/**
 * Gets value chains
 */
export const getValueChains = async () => {
    const response = await getRemoteData(`${API_URL}/value-chains`, 'GET', 'getValueChains');
    return response;
};

/**
 * Gets all active sellers
 */
export const getSellers = async sellerId => {
    const user = await retrieveUserInfoInLocalStorage();
    const url = user.role_type === 'admin' ? (sellerId ? `${API_URL}/sellers?user.id=${sellerId}` : `${API_URL}/sellers`) : `${API_URL}/sellers?user.id=${user.id}`;
    const response = await getRemoteData(url, 'GET', 'getSellers');
    return response;
};
/**
 * Get Seller list or single one
 * @param {*} id 
 * @returns 
 */
//  export const getSellersList = async () => {
//   const user = await retrieveUserInfoInLocalStorage();
//   const url = user.role_type === 'admin' ? `${API_URL}/users?role.type=seller` : `${API_URL}/users/${user.id}`;
//   const response = await getRemoteData(url, 'GET', 'getSellersList');
//   return response;
// };
/**
 * Gets all active sellers list
 */
export const getSellersList = async () => {
  const user = await retrieveUserInfoInLocalStorage();
  console.log(user, "_____________");
  const url =
    user.role_type === "admin" ||
    user?.roles === "Admin" ||
    user?.roles === "admin"
      ? `${API_URL}/users?role.type=seller`
      : `${API_URL}/users/${user.id}`;
  console.log("url to fetch seller list", url);
  const response = await getRemoteData(url, "GET", "getSellersList");
  return response;
};

/**
 * Gets selected seller profile
 * @param {*} id
 */
export const getSellerInfo = async id => {
    const response = await getRemoteData(`${API_URL}/section-values?user.id=${id}`, 'GET', 'getSellerInfo');
    return response;
};

/**
 * Uploads Images to Cloudinary
 * @param {Array} uploadedImages
 */
export const uploadProductImages = async uploadedImages => {
    const imageLinks = [];
    var errorMessage = '';
    if (uploadedImages.length !== 0) {
        for (var i = 0; i < uploadedImages.length; i++) {
            const imageBanners = new FormData();
            imageBanners.append('upload_preset', CLOUDINARY_PRESET);
            imageBanners.append('file', uploadedImages[i]);
            const cloudResponse = await uploadImageOnCloudinary(imageBanners);
            // console.log("cloud response is", cloudResponse);
            if (cloudResponse.has_error) {
                errorMessage = cloudResponse.errors[0];
                break;
            }
            if (cloudResponse.data.secure_url) {
                const savedImages = await getRemoteData(`${API_URL}/product-images`, 'POST', 'uploadProductImages', true, {
                    url: cloudResponse.data.secure_url,
                    is_main: true
                });

                imageLinks.push({
                    id: savedImages.data.id,
                    url: savedImages.data.url,
                    is_main: savedImages.data.is_main
                });
            }
        }
    }

    if (errorMessage !== '') {
        /**
         * Display error message
         */
        // console.log(errorMessage);
        return;
    }

    return imageLinks;
};

/**
 * Save Product information to API
 * @param {*} productName
 * @param {*} selectedValueChain
 * @param {*} productImages
 * @param {*} selectedStatus
 * @param {*} selectedSeller
 * @param {*} isProductFeatured
 * @param {*} isAllowedReviews
 * @param {*} productRegularPrice
 * @param {*} productSalesPrice
 * @param {*} productShortDescription
 * @param {*} productCode
 * @param {*} productInStock
 * @param {*} productMinimumOrder
 * @param {*} productMaximumOrder
 * @param {*} productDescription
 */

export const saveProduct = async (body) => {
  const response = await getRemoteData(
    `${API_URL}/products`,
    "POST",
    "saveProduct",
    true,
    body
  );
  return response;
};

export const changeStatusProduct = async (id, status) => {
    const response = await getRemoteData(`${API_URL}/products/${id}`, 'PUT', 'changeStatusProduct', true, { status });
    return response;
};

export const editProduct = async (selectedProduct, body) => {
  const response = await getRemoteData(
    `${API_URL}/products/${selectedProduct}`,
    "PUT",
    "editProduct",
    true,
    body
  );
  return response;
};

export const listProducts = async () => {
    const user = await retrieveUserInfoInLocalStorage();
    const url = user.role_type === 'admin' ? `${API_URL}/products?_sort=id:desc` : `${API_URL}/products?_sort=id:desc&user.id=${user.id}`;
    const response = await getRemoteData(url, 'GET', 'listProducts');
    return response;
};

/**
 * Delete a product from DB
 * @param {*} id
 */
export const deleteProductByID = async id => {
    const response = await getRemoteData(`${API_URL}/products/${id}`, 'DELETE', 'deleteProductByID', true, {});
    return response;
};

/**
 * Fetching Product info by ID
 * @param {*} id
 */
export const getSelectedProduct = async (id) => {
  const response = await getRemoteData(
    `${API_URL}/products/${id}`,
    "GET",
    "getSelectedProduct",
    true
  );
  return response;
};
