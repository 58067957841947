import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { setEnableMobileMenu } from '../../reducers/ThemeOptions';
import { logoutUser, retrieveUserInfoInLocalStorage } from '../../helpers/auth';
import { getRemoteData } from '../../helpers/remote';
import { API_URL } from '../../config/app';

const adminNav = [
   {
      icon: 'pe-7s-id',
      label: 'Overview',
      to: '#/overview'
   }
];

const marketAgentsNav = [
   {
      icon: 'pe-7s-id',
      label: 'Overview',
      to: '#/overview'
   },
   // {
   //    icon: 'pe-7s-graph3',
   //    label: 'Wholesale Analytics',
   //    to: '#/wholesale/analytics'
   // },
   // {
   //    icon: 'pe-7s-graph1',
   //    label: 'Retail Analytics',
   //    to: '#/retail/analytics'
   // },
   // {
   //    icon: 'pe-7s-graph2',
   //    label: 'Farmgate Analytics',
   //    to: '#/farmgate/analytics'
   // },
   {
      icon: 'pe-7s-graph1',
      label: 'Reports',
      to: '#/combined/analytics'
   },
   {
      icon: 'pe-7s-id3',
      label: 'Products',
      to: '#/productslist'
   }
   // {
   //    icon: 'pe-7s-graph',
   //    label: 'Legacy Data',
   //    to: '#/legacy/analytics'
   // }
];

const analystsNav = [
   {
      icon: 'pe-7s-id',
      label: 'Overview',
      to: '#/overview'
   },
   {
      icon: 'pe-7s-cart',
      label: 'Price Entrices',
      to: '#/product-price-list'
   },
   // {
   //    icon: 'pe-7s-graph3',
   //    label: 'Wholesale Analytics',
   //    to: '#/wholesale/analytics'
   // },
   // {
   //    icon: 'pe-7s-graph1',
   //    label: 'Retail Analytics',
   //    to: '#/retail/analytics'
   // },
   // {
   //    icon: 'pe-7s-graph2',
   //    label: 'Farmgate Analytics',
   //    to: '#/farmgate/analytics'
   // },
   {
      icon: 'pe-7s-graph1',
      label: 'Reports',
      to: '#/combined/analytics'
   },
   {
      icon: 'pe-7s-cart',
      label: 'Products',
      to: '#/productslist'
   }
   // {
   //    icon: 'pe-7s-graph',
   //    label: 'Legacy Data',
   //    to: '#/legacy/analytics'
   // },
];

const publicUserNav = [
   {
      icon: 'pe-7s-id',
      label: 'Overview',
      to: '#/overview'
   },
   {
      icon: 'pe-7s-graph3',
      label: 'Wholesale Analytics',
      to: '#/wholesale/analytics'
   },
   {
      icon: 'pe-7s-graph1',
      label: 'Retail Analytics',
      to: '#/retail/analytics'
   },
   {
      icon: 'pe-7s-graph2',
      label: 'Farmgate Analytics',
      to: '#/farmgate/analytics'
   }
   //  {
   //     icon: 'pe-7s-id3',
   //     label: 'pe-7s-cart',
   //     to: '#/productslist'
   //  },
   //  {
   //     icon: 'pe-7s-graph3',
   //     label: 'Legacy Data',
   //     to: '#/legacy/analytics'
   //  }
];
const privilegedAdminNav = adminNav;
// privilegedAdminNav.push({
//    icon: 'pe-7s-graph3',
//    label: 'Wholesale Analytics',
//    to: '#/wholesale/analytics'
// });
// privilegedAdminNav.push({
//    icon: 'pe-7s-graph1',
//    label: 'Retail Analytics',
//    to: '#/retail/analytics'
// });
// privilegedAdminNav.push({
//    icon: 'pe-7s-graph2',
//    label: 'Farmgate Analytics',
//    to: '#/farmgate/analytics'
// });
privilegedAdminNav.push({
   icon: 'pe-7s-cart',
   label: 'Price Entries',
   to: '#/product-price-list'
});
privilegedAdminNav.push({
   icon: 'pe-7s-graph1',
   label: 'Reports',
   to: '#/combined/analytics'
});
privilegedAdminNav.push({
   icon: 'pe-7s-cart',
   label: 'Commodities',
   to: '#/productslist'
});
privilegedAdminNav.push({
   icon: 'pe-7s-graph2',
   label: 'Markets',
   to: '#/marketslist'
});
privilegedAdminNav.push({
   icon: 'pe-7s-users',
   label: 'Users',
   to: '#/usersList'
});
// privilegedAdminNav.push({
//    icon: 'pe-7s-graph',
//    label: 'Legacy Data',
//    to: '#/legacy/analytics'
// });
privilegedAdminNav.push({
   icon: 'pe-7s-graph3',
   label: 'Stats',
   to: '#/stats'
});

class Nav extends Component {
   constructor(props) {
      super(props);
      this.state = {
         appNavigator: [],
         navItemSet: false
      };
      this.getLoggedInUser = this.getLoggedInUser.bind(this);
   }

   componentDidMount() {
      this.getLoggedInUser();
   }

   getLoggedInUser = async () => {
      try {
         const user = await retrieveUserInfoInLocalStorage();

         console.log('here is the user in nav manu', user);
         if (user.roles.includes('Admin') || user.roles.includes('admin')) {
            this.setState({
               appNavigator: adminNav
            });
         } else if (user.roles.includes('Esoko Manager') || user.roles.includes('esoko manager')) {
            this.setState({
               appNavigator: adminNav
            });
         } else if (user.roles.includes('Analyst') || user.roles.includes('analyst')) {
            this.setState({
               appNavigator: analystsNav
            });
         } else if (user.roles.includes('Guest') || user.roles === 'Guest' || user.roles.includes('guest')) {
            this.setState({
               appNavigator: publicUserNav
            });
         }
         // else if (user.roles.includes('Agent')) {
         //    this.setState({
         //       appNavigator: marketAgentsNav
         //    });
         // }
         else {
            await logoutUser();
            window.location.href = '/';
            return;
            const url = `${API_URL}/access-privileges?identifier=receiving-client-info&users=${user.id}?`;
            const data = await getRemoteData(url, 'GET', 'getLoggedInUser');
            if (data.data.length > 0) {
               this.setState({
                  appNavigator: privilegedAdminNav
               });
            } else {
               this.setState({
                  appNavigator: adminNav
               });
            }
         }
      } catch (error) {
         console.log('an error happened in Layout/AppNav/VerticalNavWrapper', error);
      }
   };

   toggleMobileSidebar = () => {
      let { enableMobileMenu, setEnableMobileMenu } = this.props;
      setEnableMobileMenu(!enableMobileMenu);
   };

   render() {
      const { appNavigator } = this.state;
      return (
         <Fragment>
            {/* <h5 className="app-sidebar__heading">Menu</h5> */}
            <MetisMenu
               content={appNavigator}
               onSelected={this.toggleMobileSidebar}
               activeLinkFromLocation
               className="vertical-nav-menu"
               iconNamePrefix=""
               classNameStateIcon="pe-7s-angle-down"
            />
         </Fragment>
      );
   }

   isPathActive(path) {
      return this.props.location.pathname.startsWith(path);
   }
}
const mapStateToProps = state => ({
   enableMobileMenu: state.ThemeOptions.enableMobileMenu
});

const mapDispatchToProps = dispatch => ({
   setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
