/* eslint-disable no-unused-expressions */
import { Route } from 'react-router-dom';
import React, { Suspense, lazy, Fragment, Component } from 'react';
import Loader from 'react-loaders';
import Global from '../../common/Global';
import { retrieveUserInfoInLocalStorage } from '../../helpers/auth';

const Login = lazy(() => import('../../Pages/UserPages/Login'));
const Forgot = lazy(() => import('../../Pages/UserPages/Forgot'));
const Reset = lazy(() => import('../../Pages/UserPages/Reset'));
const Retail = lazy(() => import('../../Pages/Retail'));
const Farmgate = lazy(() => import('../../Pages/Farmgate'));
const Wholesale = lazy(() => import('../../Pages/Wholesale'));
const CombinedReports = lazy(() => import('../../Pages/Combined'));
const ProductsList = lazy(() => import('../../Pages/Products/list'));
const MarketsList = lazy(() => import('../../Pages/Markets/list'));
const MarketDetails = lazy(() => import('../../Pages/Markets/detials'));
const AgentsList = lazy(() => import('../../Pages/Agents/list'));
const AgentDetails = lazy(() => import('../../Pages/Agents/detials'));
const Overview = lazy(() => import('../../Pages/Overview'));
const ProductPriceList = lazy(() => import('../../Pages/EsokoPrices/list'));
const ProductPriceDetials = lazy(() => import('../../Pages/EsokoPrices/detials'));
const prodA = lazy(() => import('../../Pages/Products/detials'));
const Legacy = lazy(() => import('../../Pages/LegacyData'));
const Stats = lazy(() => import('../../Pages/Stats'));

class AppMain extends Component {
   constructor(props) {
      super();
      this.state = {
         path: '',
         activeUserRole: ''
      };
   }

   componentDidMount() {
      this.getloggedInUser();
   }

   getloggedInUser = async () => {
      const authUser = await retrieveUserInfoInLocalStorage();
      console.log('author user in app main', authUser);
      if ((authUser && authUser.roles && authUser.roles.length > 0) || authUser?.roles === 'Guest') {
         const role = authUser.roles[0] || authUser.roles;
         console.log('in main app condition');
         this.setState({
            path: '/overview',
            activeUserRole: role
         });
         // if (role === "admin") {
         //   this.setState({
         //     path: "/overview",
         //     activeUserRole: role,
         //   });
         // } else if (role === "seller") {
         //   this.setState({
         //     path: `/overview?seller=${authUser.id}`,
         //     activeUserRole: role,
         //   });
         // }
      }
   };

   render() {
      return (
         <Fragment>
            {/* Login */}

            <Suspense
               fallback={
                  <div className="loader-container">
                     <div className="loader-container-inner">
                        <div className="text-center">
                           <Loader type="line-scale-party" />
                        </div>
                     </div>
                  </div>
               }
            >
               <Route exact path="/" component={Login} />
            </Suspense>

            {/* Forgot */}

            <Suspense
               fallback={
                  <div className="loader-container">
                     <div className="loader-container-inner">
                        <div className="text-center">
                           <Loader type="line-scale-party" />
                        </div>
                     </div>
                  </div>
               }
            >
               <Route exact path="/forgot" component={Forgot} />
            </Suspense>

            {/* Reset */}

            <Suspense
               fallback={
                  <div className="loader-container">
                     <div className="loader-container-inner">
                        <div className="text-center">
                           <Loader type="line-scale-party" />
                        </div>
                     </div>
                  </div>
               }
            >
               <Route exact path="/reset" component={Reset} />
            </Suspense>

            {/* Analytics */}

            {/* <Suspense
          fallback={
            <div className='loader-container'>
              <div className='loader-container-inner'>
                <div className='text-center'>
                  <Loader type='ball-pulse' />
                </div>
              </div>
            </div>
          }
        >
          <Route path='/analytics' component={Analytics} />
        </Suspense> */}

            <Suspense
               fallback={
                  <div className="loader-container">
                     <div className="loader-container-inner">
                        <div className="text-center">
                           <Loader type="ball-pulse" />
                        </div>
                     </div>
                  </div>
               }
            >
               <Route path="/Retail" component={Retail} />
               <Route path="/Wholesale" component={Wholesale} />
               <Route path="/Farmgate" component={Farmgate} />
               <Route path="/Combined" component={CombinedReports} />
               <Route path="/ProductsList" component={ProductsList} />
               <Route path="/MarketsList" component={MarketsList} />
               <Route path="/market" component={MarketDetails} />
               <Route path="/userslist" component={AgentsList} />
               <Route path="/product-detials" component={prodA} />
               <Route path="/agent" component={AgentDetails} />
               <Route path="/product-price-list" component={ProductPriceList} />
               <Route path="/Overview" component={Overview} />
               <Route path="/product-price-detials" component={ProductPriceDetials} />
               <Route path="/Legacy" component={Legacy} />
               <Route path="/stats" component={Stats} />
            </Suspense>

            {/* Dashboards */}

            {/* <Route exact path="/" render={() => (
                <Redirect to="/dashboard"/>
            )}/> */}
         </Fragment>
      );
   }
}

export default AppMain;
